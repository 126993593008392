var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"venue-settings"},[(_vm.venue)?_c('StatusLabel',{attrs:{"page":"venue","status":_vm.venue.isActive ? 'active' : 'inactive'}}):_vm._e(),_c('div',{staticClass:"venue-container"},[_c('div',{staticClass:"side column is-4"},[_c('BackButton'),_c('ul',{staticClass:"menu"},_vm._l((_vm.PAGES),function(item){return _c('li',{key:item,staticClass:"menu-item"},[_c('router-link',{class:[
              'menu-item-link',
              {
                'router-link-exact-active': _vm.$route.name.includes(
                  _vm.ROUTE_LIST_NAMES.VENUE_SETTINGS[item]
                )
              }
            ],attrs:{"to":{ name: _vm.ROUTE_LIST_NAMES.VENUE_SETTINGS[item] },"venue":_vm.venue,"exact":""}},[(
                _vm.$route.name.includes(_vm.ROUTE_LIST_NAMES.VENUE_SETTINGS[item])
              )?_c('i',{staticClass:"fa-solid fa-chevron-right fa-xs"}):_vm._e(),_c('span',{staticStyle:{"margin-left":"5px"}},[_vm._v(_vm._s(_vm.$t( ("onboarding.venue.side_menu.label_" + (item.toLowerCase()) + "_link") )))])])],1)}),0),(
          _vm.venue &&
          _vm.$route.name.includes(_vm.ROUTE_LIST_NAMES.VENUE_SETTINGS.DETAILS)
        )?_c('SideActions',{attrs:{"is-active":_vm.venue.isActive,"slug":_vm.venue.slug,"page":'venue'},on:{"onTrash":function($event){_vm.isDeleteConfirmationVisible = true},"open-share":function($event){_vm.isShareModalOpened = true}}}):_vm._e()],1),(_vm.$route.params.venueId && !_vm.isDataLoaded)?_c('div',{staticClass:"venue-pages column is-8"},[_c('PageLoadingBar')],1):_c('router-view',{staticClass:"venue-pages column is-8",attrs:{"venue":_vm.venue}})],1),(_vm.isDeleteConfirmationVisible)?_c('DeleteConfirmation',{attrs:{"remove-text":_vm.$t('components.delete_confirmation.button_label_remove'),"description":_vm.$t('components.delete_confirmation.label_description').toString()},on:{"remove":_vm.removeVenue,"close":_vm.hideDeleteConfirmation}}):_vm._e(),(_vm.isShareModalOpened)?_c('SocialSharing',{directives:[{name:"scroll-lock",rawName:"v-scroll-lock",value:(_vm.isShareModalOpened),expression:"isShareModalOpened"}],attrs:{"data":_vm.venue},on:{"close":function($event){_vm.isShareModalOpened = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }