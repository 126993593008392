import { defineComponent, ref, computed, onMounted, onBeforeMount } from '@vue/composition-api';
import { ROUTE_LIST_NAMES } from '@/config/router/appRoutes';
import BackButton from '@/components/BackButton.vue';
import DeleteConfirmation from '@/shared/components/DeleteConfirmation.vue';
import SideActions from '@/shared/components/SideActions.vue';
import StatusLabel from '@/shared/components/StatusLabel.vue';
import SocialSharing from '@/Space/components/SocialSharing.vue';
import PageLoadingBar from '@/shared/components/PageLoadingBar.vue';
const PAGES = ['DETAILS', 'FACILITIES', 'CATERING', 'TAGS'];
import { ADD_TOAST_MESSAGE } from 'vuex-toast';
export default defineComponent({
    components: {
        BackButton,
        DeleteConfirmation,
        SideActions,
        StatusLabel,
        PageLoadingBar,
        SocialSharing
    },
    setup(_, context) {
        const { root } = context;
        const isShareModalOpened = ref(false);
        const venuesList = computed(() => globalThis.$store.getters['$_vendor/venuesList']);
        const venue = computed(() => {
            return venuesList.value.find(v => Number(v.id) === Number(root.$route.params.venueId));
        });
        const isDeleteConfirmationVisible = ref(false);
        const isDataLoaded = ref(false);
        const hideDeleteConfirmation = () => {
            isDeleteConfirmationVisible.value = false;
        };
        onBeforeMount(() => {
            globalThis.$store.commit('$_vendor/SET_VENUE_ID', Number(root.$route.params.venueId));
        });
        onMounted(async () => {
            await globalThis.$store
                .dispatch('$_vendor/initSpecificVenueDetails', root.$route.params.venueId)
                .then(() => {
                isDataLoaded.value = true;
            });
        });
        const removeVenue = async () => {
            const venueName = venue.value.name;
            const addToast = globalThis.$store.dispatch.bind(null, ADD_TOAST_MESSAGE);
            try {
                await globalThis.$store.dispatch('$_vendor/removeVenue', venue.value.id);
                globalThis.$router.push({ name: ROUTE_LIST_NAMES.SPACES });
                addToast({
                    text: root.$i18n.t('common.errors.delete.success', {
                        location_type: root.$route.params.locale === 'en' ? 'Venue' : 'Locatie',
                        name: venueName
                    }),
                    type: 'success',
                    dismissAfter: 5000
                });
            }
            catch (e) {
                if (e.data.status_code === 403) {
                    addToast({
                        text: root.$i18n.t('common.errors.delete.reject', {
                            location_type: root.$route.params.locale === 'en' ? 'venue' : 'locatie'
                        }),
                        type: 'danger',
                        dismissAfter: 5000
                    });
                }
            }
        };
        return {
            isDataLoaded,
            isDeleteConfirmationVisible,
            isShareModalOpened,
            venue,
            venuesList,
            ROUTE_LIST_NAMES,
            PAGES,
            hideDeleteConfirmation,
            removeVenue
        };
    }
});
